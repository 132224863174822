import React, { useMemo, useState } from "react";
import "./styles.css";
import mixpanel from "../../mixpanel";
import { MIXPANEL_EVENTS } from "../../mixpanel/events";
import { Button } from "../../components";
import Lottie from "lottie-react";
import muscleJson from '../../muscle.json'
import { useNavigate } from 'react-router-dom';

export default function LandingPage() {
  const navigate = useNavigate();

  async function onGetStarted() {
    mixpanel.track(MIXPANEL_EVENTS.GET_STARTED);
    navigate('/d')
  }

  return (
    <div className="landing">
      <header>
        <span className="supercoach-landing">SuperCoach</span>
        <Lottie animationData={muscleJson} style={{width: 35, height: 35}}/>
      </header>
      <section className="main-section">
        <h3>
          Crea entrenamientos <br></br> para ti o tus clientes <br></br>{" "}
          <span className="blue">en minutos</span>
        </h3>
        <p>
          SuperCoach te permite crear entrenamientos personalizados para ti o
          tus clientes en menos de un minuto.
        </p>
        <Button onClick={onGetStarted} size={'lg'} label={'Comenzar'} color={'messenger'} style={{width: '100%', maxWidth: 400, height: 60, fontSize: 22, borderRadius: 16}}/>
      </section>
    </div>
  );
}
