import React, { useMemo, useState } from "react";
import "./styles.css";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  useToast, 
} from "@chakra-ui/react";
import { Button, CenterContent, WorkoutPlan, CircularProgress } from "../../components";
import { translations } from "../../translations";
import mixpanel from "../../mixpanel";
import { MIXPANEL_EVENTS } from "../../mixpanel/events";
import { useNavigate } from "react-router-dom";

const url = "https://0ecjej8xoe.execute-api.us-west-1.amazonaws.com/";
const endpoint = "api/v1/create-workout-plan";
const uri = `${url}${endpoint}`;

const EQUIPMENT = {
  BODY_ONLY: "body_only",
  BARBELL: "barbell",
  DUMBBELL: "dumbbell",
  BENCH: "bench",
  PULLUP_BAR: "pullup_bar",
  KETTLEBELL: "kettlebell",
  STRENGTH_MACHINE: "strength_machine",
  CARDIO_MACHINE: "cardio_machine",
  BANDS: "bands",
  EZ_CURL_BAR: "ez_curl_bar",
  WEIGHT_PLATE: "weight_plate",
  EXERCISE_BALL: "exercise_ball",
  MEDICINE_BALL: "medicine_ball",
  STEP_BOX: "step_box",
  OTHERS: "others",
};

const WORKOUT_TARGET = {
  FULL_BODY: "full_body",
  UPPER_BODY: "upper_body",
  LOWER_BODY: "lower_body",
  UPPER_LEGS: "upper_legs",
  LOWER_LEGS: "lower_legs",
  GLUTES: "glutes",
  CORE: "core",
  BACK: "back",
  CHEST: "chest",
  SHOULDERS: "shoulders",
  TRICEPS: "triceps",
  BICEPS: "biceps",
  FOREARMS: "forearms",
  CARDIO: "cardio",
};

const WORKOUT_LEVEL = {
  BEGINNER: "beginner",
  INTERMEDIATE: "intermediate",
  ADVANCED: "advanced",
};

export default function HomePage() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const toast = useToast();

  const [workout, setWorkout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [workoutInput, setWorkoutInput] = useState({
    level: null,
    target: [],
    days: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
    equipment: [],
  });

  const resetWorkoutInput = () => {
    setWorkoutInput({
      level: null,
      target: [],
      days: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      equipment: [],
    });
  };

  async function onCreateWorkout() {
    mixpanel.track(MIXPANEL_EVENTS.CREATE_WORKOUT);
    try {
      setLoading(true);
      console.log("creating workout plan...");
      const response = await fetch(uri, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ workoutInput }),
      });

      const json = await response.json();

      const { data, message } = json.body;

      if (json.status === 200) {
        console.log(message);
        setWorkout(data);
        console.log(data);
      }

      if (json.status === 400) {
        setError(message);
        console.log(json.error);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function updateLevel(level) {
    setWorkoutInput((prev) => ({
      ...prev,
      level,
    }));
  }

  function updateDay(day) {
    setWorkoutInput((prev) => ({
      ...prev,
      days: {
        ...prev.days,
        [day]: !prev.days[day],
      },
    }));
  }

  function updateTarget(target) {
    if (workoutInput.target.includes(target)) {
      setWorkoutInput((prev) => ({
        ...prev,
        target: prev.target.filter((t) => t !== target),
      }));
    } else {
      setWorkoutInput((prev) => ({
        ...prev,
        target: [...prev.target, target],
      }));
    }
  }

  function updateEquipment(equipment) {
    if (workoutInput.equipment.includes(equipment)) {
      setWorkoutInput((prev) => ({
        ...prev,
        equipment: prev.equipment.filter((e) => e !== equipment),
      }));
    } else {
      setWorkoutInput((prev) => ({
        ...prev,
        equipment: [...prev.equipment, equipment],
      }));
    }
  }

  const btnRef = React.useRef(null);

  const canCreateWorkout = useMemo(() => {
    return workoutInput.level &&
      Object.values(workoutInput.days).some((day) => day) &&
      workoutInput.target.length > 0 &&
      workoutInput.equipment.length > 0
      ? true
      : false;
  }, [workoutInput]);

  const onCopyLink = () => {
    navigator.clipboard.writeText("www.supercoach.com.mx/demo/" + workout.id);
    toast({
      title: "Link copiado",
      description:
        "El link de tu entrenamiento ha sido copiado al portapapeles",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    setWorkout(null);
    resetWorkoutInput();
  };

  const onGoToWorkout = () => {
    navigate("/demo/" + workout.id);
  };

  return (
    <div className="app-demo">
      <CenterContent>
        <span className="subTitle">Crea tu entrenamiento</span>
        <p className="subTitleDescription">
          Crea tu entrenamiento personalizado eligiendo la dificultad, los días
          de la semana, el objetivo y el equipo que tienes disponible.
        </p>
        <div className="group">
          <span className="label">Didificultad</span>
          <div>
            {Object.values(WORKOUT_LEVEL).map((level, i) => (
              <Button
                key={level}
                label={translations.buttons[level]}
                color={workoutInput.level === level ? "messenger" : undefined}
                variant={workoutInput.level === level ? undefined : "outline"}
                onClick={() => updateLevel(level)}
                ml={2}
                mb={2}
              />
            ))}
          </div>
        </div>

        <div className="group">
          <span className="label">Días de entrenamiento</span>
          <div>
            {Object.keys(workoutInput.days).map((day, i) => (
              <Button
                key={day}
                label={translations.days.fullDays[day]}
                color={workoutInput.days[day] ? "messenger" : undefined}
                variant={workoutInput.days[day] ? undefined : "outline"}
                onClick={() => updateDay(day)}
                ml={2}
                mb={2}
              />
            ))}
          </div>
        </div>

        <div className="group">
          <span className="label">Objetivo</span>
          <div>
            {Object.values(WORKOUT_TARGET).map((target, i) => (
              <Button
                key={target}
                size={"sm"}
                label={translations.workoutTarget[target]}
                variant={
                  workoutInput.target.includes(target) ? undefined : "outline"
                }
                color={
                  workoutInput.target.includes(target) ? "messenger" : undefined
                }
                onClick={() => updateTarget(target)}
                ml={2}
                mb={2}
              />
            ))}
          </div>
        </div>

        <div className="group">
          <span className="label">Equipo</span>
          <div>
            {Object.values(EQUIPMENT).map((val, i) => (
              <Button
                key={val}
                size={"sm"}
                label={translations.equipment[val]}
                color={
                  workoutInput.equipment.includes(val) ? "messenger" : undefined
                }
                variant={
                  workoutInput.equipment.includes(val) ? undefined : "outline"
                }
                onClick={() => updateEquipment(val)}
                ml={2}
                mb={2}
              />
            ))}
          </div>
        </div>
        <div className="group">
          <span className="label" sty>
            Tines preguntas?
          </span>
          <a
            href="https://wa.me/5214671065978"
            target="_blank"
            style={{
              fontSize: 15,
              color: "#718096",
              textDecoration: "underline",
            }}
          >
            Mandanos un mensage en whatsapp!
          </a>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Button
            label={"Crear Entrenamiento"}
            color={canCreateWorkout ? "messenger" : undefined}
            size="lg"
            isLoading={loading}
            onClick={canCreateWorkout ? onCreateWorkout : undefined}
            style={{
              borderRadius: 16,
              width: "100%",
              maxWidth: 500,
              height: 60,
              fontSize: 22,
              marginTop: 20,
              marginBottom: 20,
            }}
          />
        </div>

        <Modal
          onClose={() => {
            setWorkout(null);
            resetWorkoutInput();
          }}
          finalFocusRef={btnRef}
          isOpen={workout !== null || loading}
          scrollBehavior={"inside"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {loading
                ? "Espera un momento, estamos creando tu entrenamiento..."
                : ""}
            </ModalHeader>
            {!loading && <ModalCloseButton />}
            <ModalBody>
              {loading && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    paddingBottom: 20,
                  }}
                >
                  <CircularProgress/>
                
                </div>
              )}
              {workout && <WorkoutPlan workout={workout} full={false} />}
            </ModalBody>
            {workout && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 20,
                  borderTop: "1px solid #e2e8f0",
                  marginTop: 30,
                }}
              >
                <span
                  style={{
                    marginBottom: 30,
                    fontSize: 22,
                    fontWeight: 600,
                  }}
                >
                  Tu entrenamiento esta listo!
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    label={"Copiar link"}
                    variant={"outline"}
                    size="lg"
                    isLoading={loading}
                    onClick={onCopyLink}
                    style={{
                      borderRadius: 16,
                    }}
                  />
                  <Button
                    label={"Ver Entrenamiento"}
                    color={"messenger"}
                    size="lg"
                    isLoading={loading}
                    onClick={onGoToWorkout}
                    style={{
                      borderRadius: 16,
                    }}
                  />
                </div>
              </div>
            )}
          </ModalContent>
        </Modal>
      </CenterContent>
    </div>
  );
}
