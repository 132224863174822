import React, { useEffect, useState } from "react";
import "./styles.css";
import { CenterContent, WorkoutPlan } from "../../components";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@chakra-ui/react";

const url = "https://0ecjej8xoe.execute-api.us-west-1.amazonaws.com/";
const endpoint = "api/v1/get-workout-plan";
const uri = `${url}${endpoint}`;

export default function HomePage() {
  const { id } = useParams();

  const [workout, setWorkout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchWorkout();
  }, [id]);

  async function fetchWorkout() {
    try {
      setLoading(true);
      console.log("fetching workout plan...");
      const response = await fetch(uri, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });

      const json = await response.json();

      const { data, message } = json.body;

      if (json.status === 200) {
        console.log(message);
        setWorkout(data);
        console.log(data);
      }

      if (json.status === 400) {
        setError(message);
        console.log(json.error);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="app-workout-demo">
      <CenterContent>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress isIndeterminate color="blue.300" />
          </div>
        )} 
        {workout && <WorkoutPlan workout={workout} />}
      </CenterContent>
    </div>
  );
}
