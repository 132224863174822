import React from "react";
import { Tag } from "@chakra-ui/react";
import { LEVELS_TRANSLATIONS } from "../../utils/constants";

// sm
// md
// lg

const LevelTag = ({ level = 1, size = "lg" }) => {
  return (
    <Tag
      size={size}
      colorScheme={level < 3 ? "blue" : level > 3 ? "red" : "green"}
    >{`${LEVELS_TRANSLATIONS?.[level]}`}</Tag>
  );
};

export default LevelTag;
