import React from 'react';
import './avatar.css'; // Import your CSS file

const Avatar = () => {
  return (
    <div className="avatar">
      Coach
    </div>
  );
};

export default Avatar;