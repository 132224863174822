import React from "react";
import "./styles.css";

const WorkoutCard = ({ title, description, }) => {
    return (
        <div className="workout-card">
            <span className="workout-card-title">{`${title}`}</span>
            <p className="workout-card-description">{`${description}`}</p>
            <div className="workout-card-fotter">
                <span className="workout-card-fotter-bottom">
                    ver
                </span>
                <span className="workout-card-fotter-bottom">
                    Editar
                </span>
            </div>
        </div>
    );
};

export default WorkoutCard;