import React from "react";
import { ICONS } from "./icons";

const Icon = ({ icon, fill = "#0f1419", width = 24, height = 24 }) => {
  const { d, viewBox } = ICONS[icon];

  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={d} />
    </svg>
  );
};

export default Icon;
