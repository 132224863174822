import React, { useContext } from "react";
import "./styles.css";
import { IconButton, Button, LevelTag } from "../";
import { Tag, Spacer, Select } from "@chakra-ui/react";
import { WorkoutContext } from "../workout";

// TODO: reorder

const REPS = new Array(60).fill(0).map((_, index) => index + 1);
const DURATION = new Array(180).fill(0).map((_, index) => index + 1); // 3 min
const REST = new Array(180).fill(0).map((_, index) => index + 1); // 3 min

const WorkoutExercise = React.memo(
  ({
    exercise: editableExercise,
    roundIndex,
    exerciseIndex,
    editable = false,
  }) => {
    const { removeExercise, updateExercise } = useContext(WorkoutContext);
    
    const [editing, setEditing] = React.useState(false);
    const [reps, setReps] = React.useState(editableExercise.reps);
    const [duration, setDuration] = React.useState(editableExercise.duration);
    const [restBeforeNextExercise, setRestBeforeNextExercise] = React.useState(
      editableExercise.restBeforeNextExercise
    );

    const onSave = () => {
      updateExercise(roundIndex, exerciseIndex, {
        ...editableExercise,
        reps,
        duration,
        restBeforeNextExercise,
      });
      setReps(editableExercise.reps);
      setDuration(editableExercise.duration);
      setRestBeforeNextExercise(editableExercise.restBeforeNextExercise);
      setEditing(false);
    };

    const onDelete = () => {
      removeExercise(roundIndex, exerciseIndex);
    };

    const onRepsChange = (event) => {
      setReps(Number(event.target.value));
    };

    const onDurationChange = (event) => {
      setDuration(Number(event.target.value));
    };

    const onRestChange = (event) => {
      setRestBeforeNextExercise(Number(event.target.value));
    };

    return (
      <>
        <div className="exercise">
          {editable ? (
            <IconButton
              disabled
              onClick={() => {}}
              iconName={"gripLinesSolid"}
              cursor={"grab"}
              marginRight={16}
            />
          ) : null}
          <div className="exercise-img-container" />
          <div className="exercise-info">
            <h4 className="exercise-title">{editableExercise.title}</h4>
            <div className="exercise-tags">
              {editing ? (
                <>
                  {editableExercise.duration && (
                    <Select
                      onChange={onDurationChange}
                      placeholder={`${editableExercise.duration} seg`}
                      mb={3}
                    >
                      {DURATION.map((rep) => (
                        <option key={rep} value={rep}>
                          {rep} seg
                        </option>
                      ))}
                    </Select>
                  )}

                  {editableExercise.reps && (
                    <Select
                      onChange={onRepsChange}
                      placeholder={`${editableExercise.reps} reps`}
                      mb={3}
                    >
                      {REPS.map((rep) => (
                        <option key={rep} value={rep}>
                          {rep} reps
                        </option>
                      ))}
                    </Select>
                  )}

                  {editableExercise.restBeforeNextExercise && (
                    <Select
                      onChange={onRestChange}
                      placeholder={`Descanso ${editableExercise.restBeforeNextExercise} seg`}
                    >
                      {REST.map((rep) => (
                        <option key={rep} value={rep}>
                          Descanso {rep} seg
                        </option>
                      ))}
                    </Select>
                  )}
                </>
              ) : (
                <>
                  {editableExercise?.duration && (
                    <Tag mr={3}>{`${editableExercise.duration} seg`}</Tag>
                  )}
                  {editableExercise?.reps && (
                    <Tag mr={3}>{`${editableExercise.reps} reps`}</Tag>
                  )}
                  {editableExercise?.weight && (
                    <Tag mr={3}>{`${editableExercise.weight} kg`}</Tag>
                  )}
                  {editableExercise?.restBeforeNextExercise && (
                    <Tag
                      mr={3}
                    >{`Descanso: ${editableExercise?.restBeforeNextExercise} seg`}</Tag>
                  )}
                </>
              )}
            </div>
          </div>
          <Spacer />
          <div className="exercise-actions">
            {editing ? (
              <>
                <Button label={"Cancelar"} onClick={() => setEditing(false)} />
                <Button
                  label={"Guardar"}
                  color={"blue"}
                  ml={3}
                  onClick={onSave}
                />
              </>
            ) : editable ? (
              <>
                <IconButton
                  onClick={() => setEditing(true)}
                  iconName={"penToSquareRegular"}
                />
                <IconButton
                  color={"red"}
                  onClick={onDelete}
                  iconName={"trashCanRegular"}
                />
              </>
            ) : (
              <LevelTag level={editableExercise?.level} size={"sm"} />
            )}
          </div>
        </div>
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.editable === nextProps.editable &&
      prevProps.exercise === nextProps.exercise
    );
  }
);

WorkoutExercise.displayName = "WorkoutExercise";

export default WorkoutExercise;
