import React from 'react';
import './styles.css'; 
import { Icon } from '..';

const SidebarItem = ({ label, iconName, uri, selected = false}) => {
  
  return (
    <div onClick={() => { window.location.href = uri }} className="sidebarItem">
      {iconName && <Icon icon={iconName} />}
      <p className={selected? 'sidebarItemText selected' : 'sidebarItemText'}>{label}</p>
    </div>
  );
};

export default SidebarItem;