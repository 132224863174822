import React from "react";
import "./index.css";
import {
  //ChakraBaseProvider,
  //extendBaseTheme,
  //theme as chakraTheme,
  ChakraProvider,
} from "@chakra-ui/react";
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";
import SuperCoachRouter from "./router";
import * as ReactDOM from "react-dom/client";

//const { Button, AlertDialog, Tag, Modal, Select, Input, Tooltip, Toast } =
//chakraTheme.components;

/*const theme = extendBaseTheme({
  components: {
    Button,
    AlertDialog,
    Tag,
    Modal,
    Select,
    Input,
    Tooltip,
    Toast,
  },
});*/

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ChakraProvider>
      <SuperCoachRouter />
    </ChakraProvider>
    {/*
        <ChakraBaseProvider theme={theme}>
      <SuperCoachRouter />
    </ChakraBaseProvider>
    
    */}
  </React.StrictMode>
);

reportWebVitals(sendToVercelAnalytics);
