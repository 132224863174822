const LEVELS_TRANSLATIONS = {
    1: "Fácil",
    2: "Fácil",
    3: "Intermedio",
    4: "Intermedio",
    5: "Difícil",
  };
  
  const MUSCLES_TRANSLATIONS = {
    abs: "Abs",
    legs: "Piernas",
    arms: "Brazos",
    back: "Espalda",
    chest: "Pecho",
    shoulders: "Hombros",
  };

  export  {
    LEVELS_TRANSLATIONS,
    MUSCLES_TRANSLATIONS,
  };
  