import { translations } from "../../translations";
import { Icon } from "../";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import "./styles.css";
import WorkoutPlanDescription from "./WorkoutPlanDescription";

const order = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
// get screen width
const width = window.innerWidth;

// CAMBIA IAMGES DE JEFIT POR LAS DE AWS

export default function WorkoutPlan({ workout, full = true }) {
  return (
    <div className="workout-plan-container">
      <span className="workout-plan-title" style={{ marginBottom: 22 }}>
        {workout?.title}
      </span>
      <div
        style={{ display: "flex", flexDirection: "column", marginBottom: 22 }}
      >
        <span
          className="workout-plan-description semi-bold"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Icon width={22} height={22} icon={"clockRegular"} />
          <p style={{ marginLeft: 8 }}>
            {
              Object.keys(workout.workouts).filter(
                (k) => workout.workouts[k].workout
              ).length
            }{" "}
            días por semana
          </p>
        </span>
      </div>
      <WorkoutPlanDescription description={workout?.description} full={full} />
      {full ? (
        <Tabs
          mt={8}
          size={width < 500 ? "md" : "lg"}
          isFitted
          variant="enclosed"
          colorScheme={"messenger"}
        >
          <TabList>
            {workout?.workouts &&
              Object.keys(workout.workouts)
                .sort((a, b) => order.indexOf(a) - order.indexOf(b))
                .map((day, i) => (
                  <Tab key={day}>{translations.days.shortDays[day]}</Tab>
                ))}
          </TabList>

          <TabPanels>
            {workout?.workouts &&
              Object.keys(workout.workouts)
                .sort((a, b) => order.indexOf(a) - order.indexOf(b))
                .map((day, i) => (
                  <TabPanel key={day} padding={0}>
                    <div
                      style={{
                        padding: 16,
                        paddingTop: 22,
                        paddingBottom: 22,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {workout.workouts[day]?.workout?.title ? (
                        <span
                          className="workout-plan-day-title"
                          style={{ marginBottom: 16 }}
                        >
                          {workout.workouts[day]?.workout?.title}
                        </span>
                      ) : (
                        <span
                          className="workout-plan-day-title"
                          style={{ marginBottom: 16 }}
                        >
                          {translations.days.fullDays[day]}
                        </span>
                      )}

                      <div>
                        {workout.workouts[day].workout ? (
                          workout.workouts[day].workout.exercises.map(
                            (exercise, i) => (
                              <div
                                key={exercise.id}
                                className="workout-plan-exercise"
                              >
                                <img
                                  style={{
                                    borderRadius: 16,
                                    width: 80,
                                    height: 80,
                                    resizeMode: "cover",
                                    marginRight: 16,
                                  }}
                                  src={'https://super-coach.s3.us-west-1.amazonaws.com/exercises/img/'+exercise.id+'.jpg'}
                                  alt={exercise.name}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span className="workout-plan-exercise-name">
                                    {exercise.name}
                                  </span>
                                  <span className="workout-plan-exercise-work">
                                    {exercise.sets} x {exercise.work.join(", ")}
                                  </span>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <div style={{ height: 400 }}>
                            <span>Día de descanso</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </TabPanel>
                ))}
          </TabPanels>
        </Tabs>
      ) : null}
    </div>
  );
}
