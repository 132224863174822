import React from 'react';
import './styles.css'
import { Sidebar, MainContent, Title, WorkoutCard, } from '../../components';
import { Box } from '@chakra-ui/react';


export default function WorkoutsPage() {
  return (
    <div className="app">
      <Sidebar />
      <MainContent>
        <Title title={'Entrenamientos'} marginBottom={24}/>
        <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
          <WorkoutCard title={'Entrenamiento 1'} description={'Pleasant middle-aged, American voice for narratio'} />
          <WorkoutCard title={'Entrenamiento 2'} description={'Pleasant middle-aged, American voice for narratio'} />
          <WorkoutCard title={'Entrenamiento 3'} description={'Pleasant middle-aged, American voice for narratio'} />
          <WorkoutCard title={'Entrenamiento 4'} description={'Pleasant middle-aged, American voice for narratio'} />
        </Box>
      </MainContent>
    </div>
  );
}