import mixpanel from "mixpanel-browser";

const DEV = process.env.NODE_ENV === "development";

const TOKEN = DEV
  ? "24e849bb773eb441236fde6bd56bc1e8"
  : "130bdb419d9065a87baa04064baf4a29";

mixpanel.init(TOKEN, {
  debug: DEV,
  track_pageview: true,
  persistence: "localStorage",
});

// Set this to a unique identifier for the user performing the event.
//

async function identify(id) {
    mixpanel.identify(id)
}

async function track(event, params) {
  mixpanel.track(event, params);
}

export default { track, identify, };
