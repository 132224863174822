import React, { useMemo, useState } from "react";
import "./styles.css";
import { useNavigate } from 'react-router-dom';

export default function LandingPage() {

  return (
    <div className="privacy-container">
      <h1>Política de Privacidad</h1>
        <p>Última actualización: 1 de mayo 2024</p>
        <p>Esta Política de Privacidad describe cómo se recopila, utiliza y comparte la información cuando utilizas la aplicación móvil Fitzome ("la Aplicación").</p>
        <h2>Información que Recopilamos</h2>
        <p>Fitzome no recopila ningún tipo de información personal identificable sobre los usuarios de la aplicación. No recopilamos nombres, direcciones de correo electrónico, números de teléfono u otra información personal.</p>
        <h2>Información de Acceso y Permisos</h2>
        <p>La aplicación Fitzome no solicita ni requiere ningún permiso especial en tu dispositivo móvil. No accede a la cámara, micrófono, ubicación u otros datos del dispositivo.</p>
        <h2>Cómo Usamos tu Información</h2>
        <p>Dado que no recopilamos información personal identificable, no hay ningún uso previsto de esta información.</p>
        <h2>Compartir tu Información</h2>
        <p>No compartimos ninguna información personal identificable sobre los usuarios de nuestra aplicación con terceros. Todos los datos generados dentro de la aplicación se mantienen localmente en el dispositivo del usuario y no se transmiten a servidores externos.</p>
        <h2>Seguridad de la Información</h2>
        <p>Aunque no recopilamos información personal identificable, tomamos medidas para proteger cualquier dato que pueda ser generado o almacenado localmente en el dispositivo del usuario. Utilizamos técnicas de cifrado y mantenemos actualizadas nuestras medidas de seguridad para proteger la información del usuario.</p>
        <h2>Tu Consentimiento</h2>
        <p>Al utilizar nuestra aplicación, aceptas nuestra Política de Privacidad y estás de acuerdo con sus términos.</p>
        <h2>Cambios en Esta Política de Privacidad</h2>
        <p>Podemos actualizar nuestra Política de Privacidad de vez en cuando. Te notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página.</p>
        <h2>Contacto</h2>
        <p>Si tienes alguna pregunta o inquietud sobre nuestra Política de Privacidad, por favor contáctanos en <a href="mailto:fitzome@gmail.com">fitzome@gmail.com</a>.</p>
    </div>
  );
}
