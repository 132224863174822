import React, { useMemo } from "react";
import "./styles.css";
import { Icon } from "..";

const IconButton = ({
  iconName,
  onClick,
  color,
  width = 20,
  height = 20,
  cursor = "pointer",
  disabled,
  mr = 0,
}) => {
  color = disabled ? "#eee" : color;
  cursor = disabled ? undefined : cursor;

  const style = useMemo(
    () => ({
      marginRight: mr,
      cursor,
    }),
    [mr, cursor]
  );

  return (
    <span
      className="icon-button"
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      <Icon icon={iconName} fill={color} width={width} height={height} />
    </span>
  );
};

export default IconButton;
