import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import HomePage from "../pages/homePage";
import WorkoutsPage from "../pages/workoutsPage";
import ProfilePage from "../pages/profilePage";
import ErrorPage from "../pages/errorPage";
import PrivacyPage from "../pages/privacy";

import {
  DemoPage,
  WorkoutDemoPage,
  LandingPage,
} from "../pages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "d",
    element: <DemoPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "workouts",
    element: <WorkoutsPage />,
  },
  {
    path: "profile",
    element: <ProfilePage />,
  },
  {
    path: "demo/:id",
    element: <WorkoutDemoPage />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPage />,
  }
]);

const SuperCoachRouter = () => {
  return <RouterProvider router={router} />;
};


export default SuperCoachRouter;