import React from 'react';
import './styles.css'
import { Sidebar, MainContent } from '../../components';


export default function ProfilePage() {
  return (
    <div className="app">
      <Sidebar />
      <MainContent>
        <h2>Perfil</h2>
      </MainContent>
    </div>
  );
}