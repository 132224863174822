import React from "react";
import "./styles.css";
import { SidebarItem } from "..";

const sideBar = () => {
  return (
    <div className="sidebar">
      <nav>
        <SidebarItem
          uri={`/`}
          selected
          label={"Inicio"}
          iconName={"houseSolid"}
        />
        <SidebarItem
          uri={`/workouts`}
          label={"Entrenamientos"}
          iconName={"dumbbelSolid"}
        />
        <SidebarItem
          uri={`/profile`}
          label={"Perfil"}
          iconName={"userRegular"}
        />
           <SidebarItem
          uri={`/subscription`}
          label={"Suscripción"}
          iconName={"userRegular"}
        />
        <SidebarItem
          label={"Cerrar Sesión"}
          iconName={"arrowRightFromBracket"}
        />
      </nav>
    </div>
  );
};

export default sideBar;
