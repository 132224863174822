import React from "react";
import { Button as B, Spinner, } from "@chakra-ui/react";


const Button = ({ label, onClick, ml, color, variant, isLoading = false, size, disabled, mb, style }) => {
  return (
    <B isLoading={isLoading} colorScheme={color} onClick={onClick} ml={ml} mb={mb} variant={variant} size={size} disabled={disabled} style={style}
    >
      {label}
      {isLoading && <Spinner ml={3} size="sm" /> }
    </B>
  );
};

export default Button;
