export const translations = {
    buttons: {
      createWorkout: "Crear entrenamiento",
      beginner: "Principiante",
      intermediate: "Intermedio",
      advanced: "Avanzado",
    },
    days: {
      fullDays: {
        monday: "Lunes",
        tuesday: "Martes",
        wednesday: "Miércoles",
        thursday: "Jueves",
        friday: "Viernes",
        saturday: "Sábado",
        sunday: "Domingo",
      },
      shortDays: {
        monday: "Lun",
        tuesday: "Mar",
        wednesday: "Mié",
        thursday: "Jue",
        friday: "Vie",
        saturday: "Sáb",
        sunday: "Dom",
      },
    },
    workoutTarget: {
      core: "Core",
      shoulders: "Hombros",
      cardio: "Cardio",
      chest: "Pecho",
      back: "Espalda",
      triceps: "Tríceps",
      upper_legs: "Piernas superiores",
      lower_legs: "Piernas inferiores",
      glutes: "Glúteos",
      biceps: "Bíceps",
      forearms: "Antebrazos",
      upper_body: "Parte superior del cuerpo",
      lower_body: "Parte inferior del cuerpo",
      full_body: "Cuerpo completo",
    },
    equipment: {
      body_only: "Peso corporal",
      others: "Otros",
      strength_machine: "Máquina de fuerza",
      bands: "Bandas",
      bench: "Banco",
      barbell: "Barra",
      ez_curl_bar: "Barra EZ",
      weight_plate: "Placa de peso",
      dumbbell: "Mancuerna",
      exercise_ball: "Pelota de ejercicio",
      pullup_bar: "Barra de dominadas",
      kettlebell: "Pesa rusa",
      medicine_ball: "Balón medicinal",
      step_box: "Step box",
      cardio_machine: "Máquina de cardio",
    },
  };