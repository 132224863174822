import React, { useEffect } from "react";
import {
    CircularProgress as CP,
  } from "@chakra-ui/react";


 const CircularProgress = React.memo((props) => {

    const [progress, setProgress] = React.useState(0);

    useEffect(() => {
        // Simulate progress
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 1000);
        // clean interval
        return () => {
            clearInterval(timer);
        }
    }, []);

    return (
        <CP {...props} value={progress} />
    );
}, () => true);

CircularProgress.displayName = "CircularProgress";


export default CircularProgress;