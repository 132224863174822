import React, { useCallback, useState, createContext, useMemo } from "react";
import "./styles.css";
import { useToast } from "@chakra-ui/react";
import { AlertDialog } from "../../components";
import WorkoutRounds from "./components/WorkoutRounds";
import WorkoutDescription from "./components/WorkoutDescription";
import WorkoutLink from "./components/WorkoutLink";
import WorkoutHeader from "./components/WorkoutHeader";
import WorkoutFooter from "./components/WorkoutFooter";
import WorkoutInfo from "./components/WorkoutInfo";
import { v4 as uuidv4 } from "uuid";

// update layout like cha gpt?

const w = {
  id: "6c0848c4-f6ff-4a31-8510-9444663052e2",
  ownerId: uuidv4(),
  createdAt: Date.now(),
  title: "Abs",
  description: "Abs workout for beginners.",
  level: 5,
  time: 30,
  objective: ["abs", "legs"],
  rounds: [
    {
      id: uuidv4(),
      title: "Princio",
      description: "Abs",
      restBeforeNextSerie: 60,
      exercises: [
        {
          title: "Abs",
          description: "Absdf",
          duration: 26,
          reps: null,
          restBeforeNextExercise: 10,
          weight: null,
          level: 1,
          targetMuscles: [
            {
              name: "abs",
              key: "sads",
              level: "primary",
            },
          ],
        },
        {
          title: "Another",
          description: "Abs",
          duration: null,
          reps: 26,
          restBeforeNextExercise: 20,
          weight: null,
          targetMuscles: [
            {
              name: "abs",
              key: "sads",
              level: "primary",
            },
          ],
        },
      ],
    },
    {
      id: uuidv4(),
      title: "Abs",
      description: "Abs",
      exercises: [
        {
          title: "Abs",
          description: "Absdf",
          duration: 16,
          reps: null,
          restBeforeNextExercise: 10,
          weight: null,
          level: 1,
          targetMuscles: [
            {
              name: "abs",
              key: "sads",
              level: "primary",
            },
          ],
        },
        {
          title: "Another",
          description: "Abs",
          duration: null,
          reps: 20,
          restBeforeNextExercise: 20,
          weight: null,
          targetMuscles: [
            {
              name: "abs",
              key: "sads",
              level: "primary",
            },
          ],
        },
      ],
    },
    {
      id: uuidv4(),
      title: "Ultimo",
      description: "Vuela a la calma",
      exercises: [
        {
          title: "Abs",
          description: "Absdf",
          duration: 16,
          reps: null,
          restBeforeNextExercise: 10,
          weight: null,
          level: 1,
          targetMuscles: [
            {
              name: "abs",
              key: "sads",
              level: "primary",
            },
          ],
        },
        {
          title: "Another",
          description: "Abs",
          duration: null,
          reps: 20,
          restBeforeNextExercise: 20,
          weight: null,
          targetMuscles: [
            {
              name: "abs",
              key: "sads",
              level: "primary",
            },
          ],
        },
      ],
    },
  ],
  edits: [
    {
      id: uuidv4(),
      type: "superCoach", // manual
      date: Date.now(),
    },
  ],
};

export const WorkoutContext = createContext(null);

const Workout = () => {
  const toast = useToast();

  // Local state
  const [editMode, setEditMode] = useState(false);
  const [editableWorkout, setEditableWorkout] = useState({ ...w });
  const [deleteDialogisOpen, setDeleteDialogisOpen] = useState(false);
  const [title, setTitle] = useState(w.title);
  const [description, setDescription] = useState(w.description);
  const [selectedRounds, setSelectedRounds] = useState([]);

  // Refs
  const cancelDeleteDialogRef = React.useRef();

  // TODO: Calculate time

  const onEditWorkout = useCallback(() => {
    setEditMode(true);
  }, []);

  const onDisableEditMode = useCallback(() => {
    setEditMode(false);
  }, []);

  const resetValuesToDefault = useCallback(() => {
    setEditableWorkout({ ...w });
    setTitle(w.title);
    setDescription(w.description);
  }, []);

  const onCancelEditWorkout = useCallback(() => {
    resetValuesToDefault();
    onDisableEditMode();
  }, [w]);

  const onTitleChange = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const onDescriptionChange = useCallback((e) => {
    setDescription(e.target.value);
  }, []);

  const onSaveWorkout = useCallback(async () => {
    try {
      toast({
        title: "El entrenamiento ha sido actualizado correctamente.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onDisableEditMode();
    } catch (error) {
      toast({
        title: "Error al actualizar el entrenamiento.",
        description: "Por favor intenta de nuevo.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, []);

  const onOpenDeleteWorkoutDialog = useCallback(() => {
    setDeleteDialogisOpen(true);
  }, []);

  const onCloseDeleteWorkoutDialog = useCallback(() => {
    setDeleteDialogisOpen(false);
  }, []);

  const onDeleteWorkout = useCallback(async () => {
    try {
      toast({
        title: "El entrenamiento ha sido eliminado correctamente.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error al eliminar el entrenamiento",
        description: "Por favor intenta de nuevo.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      onCloseDeleteWorkoutDialog();
    }
  }, []);

  // Rounds

  const onDeleteRounds = useCallback(
    (ids) => {
      const clonedWorkout = JSON.parse(JSON.stringify(editableWorkout));
      clonedWorkout.rounds = clonedWorkout.rounds.filter(
        (round) => !ids.includes(round.id)
      );
      setEditableWorkout(clonedWorkout);
      setSelectedRounds([]);
    },
    [editableWorkout]
  );

  const updateRound = useCallback(
    (index, round) => {
      const newWorkout = { ...editableWorkout };
      newWorkout.rounds[index] = { ...round };
      setEditableWorkout(newWorkout);
    },
    [editableWorkout]
  );

  const onCopyRounds = useCallback(
    (ids) => {
      const clonedWorkout = JSON.parse(JSON.stringify(editableWorkout));
      const rounds = clonedWorkout.rounds;
      const roundsToCopy = JSON.parse(
        JSON.stringify(rounds?.filter((round) => ids.includes(round.id)))
      );
      roundsToCopy.forEach((round) => {
        // Update round id to a new one
        round.id = uuidv4();
        // Update title
        round.title = `${round.title} (Copia)`;
      });
      rounds.push(...roundsToCopy);
      setEditableWorkout({
        ...clonedWorkout,
        rounds,
      });
      setSelectedRounds([]);
    },
    [editableWorkout]
  );

  // Exercise

  const removeExercise = useCallback(
    (roundIndex, exerciseIndex) => {
      const clonedWorkout = JSON.parse(JSON.stringify(editableWorkout));
      clonedWorkout.rounds[roundIndex].exercises.splice(exerciseIndex, 1);
      setEditableWorkout(clonedWorkout);
    },
    [editableWorkout]
  );

  const updateExercise = useCallback(
    (roundIndex, exerciseIndex, exercise) => {
      const newWorkout = { ...editableWorkout };
      newWorkout.rounds[roundIndex].exercises[exerciseIndex] = { ...exercise };
      setEditableWorkout(newWorkout);
    },
    [editableWorkout]
  );

  const estimatedTime = useMemo(() => {
    let time = 0;
    editableWorkout?.rounds?.forEach((round) => {
      time +=
        round?.exercises?.reduce((acc, exercise) => {
          return (
            acc +
            (exercise?.duration || 0) +
            (exercise?.reps || 0) +
            (exercise?.restBeforeNextExercise || 0)
          );
        }, 0) + (round?.restBeforeNextSerie || 0);
    });
    return Math.round(time / 60);
  }, [editableWorkout]);

  const value = useMemo(
    () => ({
      workout: editableWorkout,
      selectedRounds,
      removeExercise,
      updateExercise,
      setSelectedRounds,
      setEditableWorkout,
      onDeleteRounds,
      onCopyRounds,
    }),
    [editableWorkout, selectedRounds]
  );

  return (
    <WorkoutContext.Provider value={value}>
      <div>
        <WorkoutHeader
          id={editableWorkout?.id}
          title={title}
          editable={editMode}
          onEditWorkout={onEditWorkout}
          onCancelEditWorkout={onCancelEditWorkout}
          onSaveWorkout={onSaveWorkout}
          onTitleChange={onTitleChange}
        />
        <WorkoutDescription
          editable={editMode}
          description={description}
          onDescriptionChange={onDescriptionChange}
        />

        {!editMode && (
          <WorkoutInfo
            level={editableWorkout?.level}
            time={estimatedTime}
            objective={editableWorkout?.objective}
          />
        )}

        {!editMode && (
          <div className="workout-header" style={{ marginBottom: 16 }}>
            <span className="workout-series-label">{`Rondas (${editableWorkout?.rounds.length})`}</span>
          </div>
        )}

        <WorkoutRounds
          rounds={editableWorkout?.rounds || []}
          editMode={editMode}
        />
        <WorkoutFooter
        id={editableWorkout?.id}
          editable={editMode}
          onOpenDeleteWorkoutDialog={onOpenDeleteWorkoutDialog}
        />
        <AlertDialog
          cancelRef={cancelDeleteDialogRef}
          isOpen={deleteDialogisOpen}
          onClose={onCloseDeleteWorkoutDialog}
          onDelete={onDeleteWorkout}
          title={"Eliminar entrenamiento"}
          description={
            "¿Estás seguro de que deseas eliminar este entrenamiento? Esta acción no se puede deshacer."
          }
        />
      </div>
    </WorkoutContext.Provider>
  );
};

export default Workout;
